import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const LinkedinMarketing = {
    title: "Linkedin Marketing",
    url: genrateURL("Linkedin Marketing"),
    description: "Page Management | LinkedIn Campaigns | Lead Generation & Management | Training & Consulting",
    subServiceIcon: Icon.Linkdin,
    image: "Linkdin.png",
    imgTag: 'social media marketing company'
}