import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const LogoDesign = {
    title: "Logo Design",
    url: genrateURL("Logo Design"),
    description: "Logo | Themed Variations | Mascot and Characters | Business Stationery",
    subServiceIcon: Icon.logoDesign,
    image: "logoDesign.png",
    imgTag: 'Best logo designers'
}