import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const InstagramMarketing = {
    title: "Instagram Marketing",
    url: genrateURL("Instagram Marketing"),
    description: "Profile Management | Content Creation & Curation | User Generated Content | Influencer Marketing",
    subServiceIcon: Icon.InstaMarketing,
    image: "InstaMarketing.png",
    imgTag: 'social media marketing company'
}