import React, { createContext, useContext, useReducer,useState,useEffect } from 'react';
import { Link,NavLink } from 'react-router-dom';
import { Nav } from "../Components/Nav"
import { Icon } from './Assets';
import { UserForm } from './UserForm';
import { NavData } from '../Components/Nav';
import { ServiceData } from '../Data/ServiceData';
import { WebSiteData } from '../Data/WebSiteData';

const Mastercontext = createContext(); 
const initialState = {
    dropdownTab: 0,
    dropdownImg: '',
    pop: '',
    overlay: false,
    banner: 0,
    counter: false,
    hover: 1,
    serve: 0,
    testimonial: 0,
    loader: false,
    thank: false,
  };
  
  function reducer(state, action) {
    switch (action.type) {
      case 'update':
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }
  
export const MasterPage = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [mobileview, setMobileview] = useState(false);
    const [model, setModel] = useState({ img: null,mobile: null, url: null, btn: false, movement: false,copied: false });
    const [hamdrop, setHamdrop] = useState(false);
    const [subhamdrop, setSubHamdrop] = useState(false);
    const showPop = (popup) => {
        dispatch({ type: 'update', payload: { pop: popup, overlay: true } });
        document.body.classList.add('overflow-hidden');
    };
    const hidePop = (popup) => {
        if (state.pop === popup) {
            dispatch({ type: 'update', payload: { pop: '', overlay: false } });
            document.body.classList.remove('overflow-hidden');
          }
    };
    useEffect(() => {
        const handleResize = () => {
            let tablet = window.matchMedia('(min-width: 0px) and (max-width: 991px)').matches;
            setMobileview(tablet);
            setTimeout(() => {
                document.documentElement.style.setProperty('--headerheight', getComputedStyle(document.querySelector("header")).height);
            },1);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <Mastercontext.Provider value={{ state,mobileview,model,dispatch,showPop,hidePop,setModel }}>
            <header>
                <div className="container">
                    <div className="header-wrapper">
                        <div className="colA">
                            <Link className="logo" to={'/'}><img src={require('../assets/images/logo.png')} alt="Website development company" /></Link>
                        </div>
                        <div className="colB">
                            {mobileview ? 
                                <div className='mobile-view'>
                                    <button type="button" className="btn inquire-btn" onClick={()=> showPop('enquire-pop')}>Inquire Now</button>
                                    <button type='button' className="ham-btn" onClick={()=> showPop('ham-pop')}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                                :
                                <ul>
                                    <Nav Dropdown={true} className={"nav-Li"} />
                                </ul>
                            }
                        </div>
                    </div>
                </div>
            </header>
            <main>
                {children}
            </main>
            <section>
                <div className="form-sec">
                    <div className="container">
                        <div className="flex">
                            <div className="colA">
                                <div className="sub-heading">
                                    <h6>{Icon.arrow} Enquire Now</h6>
                                </div>
                                <div className="heading">
                                    <h3>Fill Out The Form And Leave Rest To Us!</h3>
                                    <p>Your best website development company is here to connect with you and bring your vision to life. Let’s discuss how your project can become a success. Don’t worry we won’t spam you.</p>
                                </div>
                            </div>
                            <div className="colB">
                                <h6>Send Us a Message and Begin the Discussion. </h6>
                                    <UserForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <Link className="logo" to={'/'}><img src={require('../assets/images/logo.png')} alt="Website development company" /></Link>
                    <div className="grid">
                        <div className="colA">
                            <h4>Quick Link</h4>
                            <ul>
                                <Nav />
                            </ul>
                        </div>
                        <div className="colB">
                            <h4>Our Services</h4>
                            <ul>
                                {WebSiteData?.footerquicklinks.map((data, index) => {
                                    const { name,url } = data;
                                    return (
                                        <li key={index}><Link to={url} onClick={() => window.scrollTo({top: 0})}>{name}</Link></li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="colc">
                            <h4>Contact Us</h4>
                            <ul className='contact'>
                                {WebSiteData && WebSiteData.contact.map((data, index) => {
                                        const { icon,items } = data;
                                        return (
                                            <li key={index}>
                                                <div className="ico">{icon}</div>
                                                <div className="url-inf">
                                                    {items && items.map((link,linkindex) => {
                                                        const { title,url } = link;
                                                        return(
                                                        <Link to={url} key={linkindex}>{title}</Link>
                                                        )
                                                    })}
                                                </div>
                                            </li>
                                        );
                                    })}
                            </ul>
                            <ul className="social-media">
                                {WebSiteData && WebSiteData.social.map((data, index) => {
                                    const { title,icon,url } = data;
                                    return (
                                        <li key={index}><Link target='_blank' to={url} title={title}>{icon}</Link></li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="bottom-sec">
                        <p>© 2024 Coding Nectar. All Rights Reserved</p>
                        <ul>
                            {NavData.slice(6,7).map((navdata,index) => {
                                const { title, url } = navdata;
                                return (
                                <li key={index}>
                                    <Link to={url} activeclassname="active" onClick={() => window.scrollTo({top: 0})}>{title}</Link>
                                </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </footer>
            {mobileview &&
                <div className='footer-strip'>
                    <ul>
                        <li><Link to={'tel:+919896946523'}><figure><img src={require('../assets/icon/call.png')} alt="" /></figure>Call</Link></li>
                        <li><Link to={'https://wa.link/l3b3t4'} target='_blank'><figure><img src={require('../assets/icon/whatsapp.png')} alt="" /></figure>Whatsapp</Link></li>
                        <li><button type='button' onClick={()=> showPop('enquire-pop')}><figure><img src={require('../assets/icon/inquiry.png')} alt="" /></figure>Enquire</button></li>
                    </ul>
                </div>
            }
            <div className={`overlay ${state.overlay ? 'is-open' : ''}`} onClick={() => {dispatch({ type: 'update', payload: {pop: '',overlay : false,}});document.body.classList.remove('overflow-hidden');}}></div>
            {mobileview &&
                <div className={`model ham-pop ${state.pop === 'ham-pop' ? 'is-open' : ''}`}>
                    <button className="close" onClick={() => {hidePop('ham-pop');setHamdrop(false);setSubHamdrop(false);}}>
                        {Icon.close}
                    </button>
                    <div className="model-body">
                    <ul className="upNav">
                    {NavData.slice(0, 6).map((navdata,index) => {
                            const { title, url } = navdata;
                            if (index === 2) {
                            return (
                                <li key={index} className="hamDropdown">
                                <button type='button' className="ham-li" onClick={() => {setHamdrop(!hamdrop);setSubHamdrop(false);}}>{title}<div className="ico"> <span></span> <span></span> </div></button>
                                    <div className={`hamdropdownmenu ${hamdrop ? 'active' : ''}`}>
                                        <ul>
                                            {ServiceData?.map((data,serviceindex) => {
                                                const { name,url: serviceUrl,subServices } = data;
                                                return (
                                                    <li key={serviceindex}>
                                                        <NavLink to={serviceUrl} onClick={() => {hidePop('ham-pop');setHamdrop(false);setSubHamdrop(false);window.scrollTo({top: 0})}}>{name}</NavLink>
                                                        {/* {subServices && <button className='show-subservice' type='button' onClick={() => setSubHamdrop(prevState => prevState === serviceindex ? null : serviceindex)}><img src={require('../assets/icon/ham-arrow.png')} alt="" /></button>}
                                                        {subServices &&
                                                            <div className={`sub-servive-drop ${subhamdrop === serviceindex ? 'open' : ''}`}>
                                                                <ul>
                                                                    {subServices?.map((data,subservice) => {
                                                                        const { title,url } = data;
                                                                        return(
                                                                            <li key={`subservice-${serviceindex}-${subservice}`}><Link to={ `${serviceUrl}`} onClick={() => {hidePop('ham-pop');setSubHamdrop(false);setHamdrop(false);window.scrollTo({top: 0})}}>{title}</Link></li>
                                                                            // for sub service page link activation
                                                                            // <li key={`subservice-${serviceindex}-${subservice}`}><Link to={ `${serviceUrl}/${url}`} onClick={() => {hidePop('ham-pop');setSubHamdrop(false);setHamdrop(false);window.scrollTo({top: 0})}}>{title}</Link></li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        } */}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </li>
                            );
                            } else {
                            return (
                                <li key={index}>
                                    <NavLink to={url} className="ham-li" onClick={() => {hidePop('ham-pop');window.scrollTo({top: 0})}}>{title}</NavLink>
                                </li>
                            );
                            }
                        })}
                    </ul>
                    <ul className="social-media">
                            {WebSiteData && WebSiteData.social.map((data, index) => {
                                const { title,icon,url } = data;
                                return (
                                    <li key={index}><Link target='_blank' to={url} title={title}>{icon}</Link></li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            }
            <div className={`model enquire-pop ${state.pop === 'enquire-pop' ? 'is-open' : ''}`}>
                <button className="close" onClick={() => hidePop('enquire-pop')}>
                    {Icon.close}
                </button>
                <div className="container">
                    <div className="heading text-center">
                        {/* <Link className="logo" to={'/'}><img src={require('../assets/images/logo.png')} alt="Website development company" /></Link> */}
                        <h3>Inquire Now</h3>
                        <p><span>Congrulations</span> You have landed safely on the land of CodingNector</p>
                    </div>
                    <div className="flex">
                        <div className="colA">
                            <div className="sub-heading">
                                <h6>{Icon.arrow} Our Services</h6>
                            </div>
                            <div className="grid">
                                {WebSiteData?.inquirypop.map((data, index) => {
                                    const { content,img,imgTag,heading,icon } = data;
                                    return (
                                        <div className="col" key={index}>
                                            <div className="ico">
                                                {icon}
                                                {/* <img src={img} alt={imgTag} /> */}
                                            </div>
                                            <div className="content">
                                                <h6>{heading}</h6>
                                                <p>{content}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="colB">
                            <UserForm/>
                        </div>
                    </div>
                </div>
            </div>
            {state.loader && <div className="loader">
                <img className='logo-loder' src={require('../assets/images/logo.png')} alt="" />
            </div>}
            {state.thank && <div className="loader thanks-pop">
                <div className="content text-center">
                    <img className='w-set' src={require('../assets/images/home/thankYou.png')} alt="" />
                    <h4>Thank you for submitting the form. We will revert you shortly!!!</h4>
                    <Link to="/" className="btn" onClick={() => { window.scrollTo({ top: 0 }); hidePop('enquire-pop'); dispatch({ type: 'update', payload: { thank: false } });}}>Back To Home</Link>
                    {/* <button type="button" className='btn' onClick={() => dispatch({ type: 'update', payload: { thank: false } })}>Back To Home</button> */}
                </div>
            </div>}
        </Mastercontext.Provider>
    );
};

export const useMaster = () => {
    return useContext(Mastercontext);
};
