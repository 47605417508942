import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const CustomWebsite = {
    title: "Custom Website",
    url: genrateURL("Custome Website"),
    description: "Tailored websites that reflect your unique brand, communicate your message, and meet specific business needs.",
    subServiceIcon: Icon.CustomWebsite,
    image: "CustomWebsite.png",
    imgTag: 'Custom Website Development agency in gurgaon'
}