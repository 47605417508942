import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const OutsourceSeo = {
    title: "Outsource SEO",
    url: genrateURL("Outsource SEO"),
    description: "Website Audit | Competitor Analysis | Site Optimization | Link Building | Ongoing SEO Maintenance ",
    subServiceIcon: Icon.outsourceSeo,
    metadescription: "This is Meta Descriptions for UI/Ux",
    metakeyword: "This is Meta Descriptions for UI/Ux",
    image: "outsourceSeo.png",
    imgTag: 'Website Development agency',
    content: `<h2>Lorem ipsum dolor sit.</h2> <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit consectetur dolorum culpa alias tenetur amet saepe nam eius deleniti? Minima.</p> <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos officiis vitae libero, eligendi unde vero, voluptatibus nemo enim obcaecati est nostrum quia temporibus odio molestiae maiores. Ea sapiente dignissimos nam.</p> <p><b>Lorem ipsum dolor sit, amet consectetur adipisicing elit:</b></p> <ul> <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, officia.</li> <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime sunt ipsam eaque laborum quas tempore dolorem esse eligendi consectetur magnam.</li> <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, officia.</li> <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime sunt ipsam eaque laborum quas tempore dolorem esse eligendi consectetur magnam.</li> <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime sunt ipsam eaque laborum quas tempore dolorem esse eligendi consectetur magnam.</li> <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, officia.</li> </ul> <h3>Lorem, ipsum dolor.</h3> <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos officiis vitae libero, eligendi unde vero, voluptatibus nemo enim obcaecati est nostrum quia temporibus odio molestiae maiores. Ea sapiente dignissimos nam.</p> <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Praesentium possimus rerum commodi cupiditate, numquam molestias sint quaerat iste aut temporibus, harum velit exercitationem cumque ab.</p> <h4>Lorem ipsum dolor sit amet, consectetur adipisicing.</h4> <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas tenetur cumque accusantium corporis cum, veniam commodi iste voluptatibus laudantium provident.</p>`,

}