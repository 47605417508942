import React,{useRef} from 'react';
import { WebSiteData } from '../Data/WebSiteData';
import { Icon } from './Assets';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Autoplay } from 'swiper/modules';

export const TeamSection = ({ additionalClass }) => {
    const teamRef = useRef(null);
    return (
        <div className={`homeSecG ${additionalClass || ''}`}>
            <div className="container">
                <div className="sub-heading text-center">
                    <h6>{Icon.arrow} Our Team</h6>
                </div>
                <div className="heading text-center">
                    <h3>Brains Behind the Operations</h3>
                </div>
                <div className="team-slider">
                    <Swiper ref={teamRef}
                        spaceBetween={10}
                        loop={true}
                        // autoplay={{
                        //   delay: 3000,
                        //   disableOnInteraction: false,
                        // }}
                        // modules={[Autoplay]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                autoHeight: true,
                                autoHeight: true
                            },
                            768: {
                                slidesPerView: 2,
                                centeredSlides: false,
                                spaceBetween:16,
                                autoHeight: true
                            },
                            992: {
                                slidesPerView: 3,
                                loop: true,
                                centeredSlides: true,
                                speed: 1000,
                                spaceBetween:0,
                                autoHeight: true
                            }
                        }}
                    >
                        {(WebSiteData?.team?.map((data, index) => {
                            const { name,description,designation,img,imgTag,socialMedia,dialog} = data;
                            return (
                                <SwiperSlide key={index}>
                                    <div className="item">
                                        <figure><img src={require(`../assets/images/team/${img}`)} alt={imgTag} /></figure>
                                        <figcaption>
                                            <div className="content">
                                                <h4>{name}</h4>
                                                <h6>{designation}</h6>
                                                <p>{description} <span>{dialog}</span> </p>
                                                {/* {socialMedia && 
                                                    <ul className="social-icon">
                                                        {socialMedia.facebook && <li><Link to={socialMedia.facebook}>{Icon.facebook}</Link></li>}
                                                        {socialMedia.instagram && <li><Link to={socialMedia.instagram}>{Icon.instagram}</Link></li>}
                                                        {socialMedia.twitter && <li><Link to={socialMedia.twitter}>{Icon.twitter}</Link></li>}
                                                        {socialMedia.linkedIn && <li><Link to={socialMedia.linkedIn}>{Icon.linkedIn}</Link></li>}
                                                    </ul>
                                                } */}
                                            </div>
                                        </figcaption>
                                    </div>
                                </SwiperSlide>
                            );
                        })) || <div className='no-data'>No data found</div>}
                    </Swiper>
                </div>
                <div className="custom-btn-arrow">
                    <button className="team-prev invert-translate" onClick={() => teamRef.current.swiper.slidePrev()}>
                        {Icon.next}
                    </button>
                    <button className="team-next" onClick={() => teamRef.current.swiper.slideNext()}>
                        {Icon.next}
                    </button>
                </div>
            </div>
        </div>
    );
};
