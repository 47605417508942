import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const Creatives = {
    title: "Creatives",
    url: genrateURL("Creatives"),
    description: "Effective Typography | Detailed Visual | Marketing Collateral | Layouts",
    subServiceIcon: Icon.creativepost,
    image:"creativepost.png",
    imgTag: 'Creative logo design'
}