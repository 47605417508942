import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '../Components/Assets';

export const Faq = ({ additionalClass, faqData }) => {
    const [activeFaq, setActiveFaq] = useState(0);
    const contentRefs = useRef([]);

    useEffect(() => {
        // Set the height of all contents to 0 except the active one
        contentRefs.current.forEach((content, index) => {
            if (content) {
                content.style.height = index === activeFaq ? `${content.scrollHeight}px` : '0';
            }
        });
    }, [activeFaq]);

    const toggleFaq = (index) => {
        setActiveFaq(prevActiveFaq => (prevActiveFaq === index ? -1 : index));
    };

    return (
        <>
            {faqData && 
                <div className={`faqSec ${additionalClass || ''}`}>
                    <div className="container">
                        <div className="flex">
                            <div className="colA">
                                <div className="sub-heading">
                                    <h6>{Icon.arrow} FAQ's</h6>
                                </div>
                                <div className="heading">
                                    <h3>Ask Any Questions</h3>
                                </div>
                                <div className="faq-sec">
                                    {faqData.map((data, index) => {
                                        const { question, answer } = data;
                                        const isActive = index === activeFaq;
                                        return (
                                            <div
                                                className={`col ${isActive ? 'active' : ''}`}
                                                key={index}
                                                onClick={() => toggleFaq(index)}
                                            >
                                                <h6>
                                                    {question} 
                                                    <div className="ico">
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </h6>
                                                <div
                                                    className="content"
                                                    ref={el => contentRefs.current[index] = el}
                                                    dangerouslySetInnerHTML={{ __html: answer }}
                                                ></div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="colB">
                                <figure>
                                    <img src={require('../assets/images/home/faqsnew.png')} alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            }   
        </>
    );
};
