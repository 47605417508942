import React from 'react';
import {Helmet} from "react-helmet";
export const TermsCondition = () => {
    return (
      <>
        <Helmet>
            <title>Terms & Conditions | Coding Nectar Website Services</title>
            <meta name="title" content="Terms & Conditions | Coding Nectar Website Services" />
            <meta name="description" content="Read Coding Nectar's terms and conditions for our website design and digital  marketing services. Understand our policies and procedures to ensure a smooth experience." />
            <meta name="keywords" content="Top Website Design in Gurgaon" />

            <meta property="og:site_name" content="Coding Nectar" />
            <meta property="og:url" content="https://www.codingnectar.com/terms-conditions" />
            <meta property="og:title" content="Terms & Conditions | Coding Nectar Website Services" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Read Coding Nectar's terms and conditions for our website design and digital  marketing services. Understand our policies and procedures to ensure a smooth experience." />
            <meta property="og:image" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
            <meta property="og:image:secure_url" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta name="twitter:site" content="@codingnectar" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Terms & Conditions | Coding Nectar Website Services" />
            <meta name="twitter:description" content="Read Coding Nectar's terms and conditions for our website design and digital  marketing services. Understand our policies and procedures to ensure a smooth experience." />
            <meta name="keywords" content="Top Website Design in Gurgaon" />
            <link rel="canonical" href="https://www.codingnectar.com/terms-conditions" />
        </Helmet>
        <div className="content-sec-A header-top-space">
            <div className="container">
              <div className="content">
                  <h1>Terms & Conditions</h1>
                  <h5>Coding Nectar - A Digital Marketing Company</h5>

                  <p>codingnectar.com is the leading design and branding online company. As a reward for our continued customer loyalty and diligence, we have earned global recognition, won awards and legal accreditation from various regulatory bodies.</p>
                  <p>Here are some of the key highlights in our policy document.</p>
                  <h3>Non-Disclosure Agreement:</h3>
                  <p>We value the privacy of our clients. Rest assured that any personal information that you provide to us when placing your order will not be rented out, shared or disclosed to third parties without your consent!</p>
                  <h3>Cookies Agreement:</h3>
                  <p>Our main reason for using cookies is not to collect information but to track clients who access our website. This information helps us to improve our service delivery to meet all clients’ needs.</p>
                  <p>Coding Nectar uses cookies policy in its site. This is designed to offer you better services especially by linking the users to the websites of the products they are viewing. You should not get disappointed when you learn that Coding Nectar uses cookies as if you don’t feel them appropriate, you can always disable them. You can disable cookies on your browser, actually on every browser such as Mozilla, internet explorer, Firefox, Google Chrome, Opera and Safari.</p>
                  
                  <h3>Terms and Conditions</h3>
                  <p>Before using Coding Nectar, you need to know the terms and conditions that govern the users. You are required to read and understand the terms and conditions before transacting any deals with us. This company is a registered sole proprieter company in India going by the company name Coding Nectar. It is located at Gurgaon – 122001 in India. This site allows you to view and read any information displayed on the site. However, using or copying the content or using the photographs or any other information on the site elsewhere is illegal and prohibited by not only Coding Nectar but also the law. In such a case you are required to have permission from the company to use this information. This site is critical to downtime and will always strive to ensure the site is active around the clock, unless the situation is beyond the company’s control. The visitor is expect to have formal conduct while surfing the site and more so in the interactive segment where visitor pose their views and comments.</p>


                  <p>For any questions, confirmations or comments, feel free to contact Coding Nectar at info@codingnectar.com. You can as well place a Toll Free call on +91 989 694 6523</p>
                  {/* <ul>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores dolor quaerat enim.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores dolor quaerat enim.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores dolor quaerat enim.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores dolor quaerat enim.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores dolor quaerat enim.</li>
                  </ul> */}
              </div>
            </div>
        </div>
      </>
    );
  };