import React, { useRef, useEffect } from 'react';
import { useMaster } from '../Components/MasterPage'
import { Icon } from './Assets';

export const PortfolioPop = ({model,setModel}) => {
    const { state,hidePop } = useMaster()
    const imgRef = useRef(null);

    const swipeboxRef = useRef(null);

    useEffect(() => {
        let timer;
        const mediaQuery = window.matchMedia('(max-width: 520px)');
        
        const handleMediaChange = () => {
            if (state.pop === 'portfolio-pop' && swipeboxRef.current) {
                if (mediaQuery.matches) {
                    swipeboxRef.current.style.display = 'block';
                    timer = setTimeout(() => {
                        swipeboxRef.current.style.display = 'none';
                    }, 4000); // 4 seconds
                } else {
                    swipeboxRef.current.style.display = 'none';
                }
            }
        };

        mediaQuery.addListener(handleMediaChange);
        handleMediaChange();

        return () => {
            clearTimeout(timer);
            mediaQuery.removeListener(handleMediaChange);
        };
    }, [state.pop]);


    const handleImageLoad = () => {
        if (imgRef.current) {
            const height = imgRef.current.offsetHeight;
            imgRef.current.parentElement.style.setProperty('--screenheight', `${height}px`);
        }
    };

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(model.url);
        setTimeout(() => {
          setModel(prevModel => ({ ...prevModel, copied: true }));
          setTimeout(() => {
            setModel(prevModel => ({ ...prevModel, copied: false }));
          }, 2000);
        }, 0);
      };
    return (
        <div className={`model portfolio-pop ${state.pop === 'portfolio-pop' ? 'is-open' : ''}`}>
            <button className="close" onClick={() => {hidePop('portfolio-pop');setModel(prevModel => ({ ...prevModel, img: null,btn: null })) }}>
                {Icon.close}
            </button>
            <div className="container">
                <div className="AwsmWeb-Wppr">
                    <div className="title">
                        <h4>design awesome stuff just for our brands</h4>
                    </div>
                    <div className='swipebox' ref={swipeboxRef}>
                        <img className="swipe-up" src={require('../assets/icon/swipegif.gif')} alt="" />
                    </div>
                    <div className="Loptop-Wppr">
                        <picture>
                            <source media="(max-width:520px)" srcSet={require('../assets/images/mobile.png')} />
                            <img className="frame" src={require('../assets/images/laptop.png')} alt="" />
                        </picture>
                        <span className={`screen ${model.btn ? 'active' : ''}`}>
                            {model.img && <img ref={imgRef} onLoad={handleImageLoad} className="website-img desktop" src={require(`../assets/images/portfolio/${model.img}`)} alt="" />}
                            {model.mobile && <img className="website-img mobile" src={require(`../assets/images/portfolio/${model.mobile}`)} alt="" />}
                        </span>
                    </div>
                    <div className="WebScrl-Btn-Wppr">
                        <button onClick={() => setModel(prevState => ({ ...prevState, btn: !prevState.btn }))}>
                            <img src={require(`../assets/icon/${model.btn ? 'onswitch.png' : 'offswitch.png'}`)} alt="" />
                        </button>
                    </div>
                    <div className="lnk-Copy-Wppr">
                        <div className="lnk-Copy">
                            <div className="text">{model.url || "No url Found"}</div>
                            <button type="button" className="lnk-Btn" onClick={copyLinkToClipboard} style={{'pointerEvents': `${model.url ? 'all' : 'none'}`}}>
                                {model.copied ? "Copied!" : "Copy Link"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
};
