import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { MasterPage } from './Components/MasterPage';
import { PageNotFound } from './Pages/PageNotFound';
import { NavData } from "./Components/Nav";
import { ServiceDetail } from './Pages/ServiceDetail';
import { SubServiceDetail } from './Pages/SubServiceDetail';
import {Helmet} from "react-helmet";
import { Thanks } from './Pages/Thanks';
import DisableShortcuts from './Components/DisableInspect';

function App() {
  return (
    <Router>
      <MasterPage>
      {/* <DisableShortcuts/> */}
        <Routes>
          {NavData && NavData.map(({ url, PageName }, index) => (
            <Route key={index} exact={url === '/'} path={url} element={<PageName />} />
          ))}
          <Route path="/:servicedetail" element={<ServiceDetail />} />
          <Route path="/:servicedetail/:subservice" element={<SubServiceDetail />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
      </MasterPage>
    </Router>
  );
}

export default App;
