import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const FacebookMarketing = {
    title: "Facebook Marketing",
    url: genrateURL("Facebook Marketing"),
    description: "Optimised Business Page | Advertising | Insights & Analytics | Content Planning & Scheduling",
    subServiceIcon: Icon.FacebookMarketing,
    image: "FacebookMarketing.png",
    imgTag: 'social media marketing company'
}