import { WebsiteDevelopment } from './Services/WebsiteDevelopment';
import { GraphicDesign } from './Services/GraphicDesign';
import { SocialMediaMarketing } from './Services/SocialMediaMarketing';
import { SearchEngineOptimization } from './Services/SearchEngineOptimization';
import { PayPerClick } from './Services/PayPerClick';
import { ContentMarketing } from './Services/ContentMarketing';

export const ServiceData = [
      WebsiteDevelopment,GraphicDesign,SearchEngineOptimization,SocialMediaMarketing,PayPerClick,ContentMarketing
];
