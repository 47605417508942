import React from 'react';
import {Helmet} from "react-helmet";
import { Banner } from '../Components/Banner';
export const FunGallery = () => {
    return (
      <>
      <Helmet>
          <title>Fun Gallery | Coding Nector</title>
          <meta name='description'content='metatags description for Fun Gallery'/>
          <meta name='keywords'content='metatags keywords for Fun Gallery'/>
      </Helmet>
      <Banner className={'fun-gallery-banner'} heading={'Fun Gallery'} subHeading={'Fun Gallery'} img={'fungallery2.png'} imgTag={'Alt title here'}/> 
      <div className='Fun_gallery_SecA'>
        <div className="container">
          <div className="insta-feeds">
            <h2>Instagram Feeds</h2>
            <div class="elfsight-app-69499bd7-3f14-45cd-93e6-324fe6fff2f8" data-elfsight-app-lazy></div>
          </div>
        </div>
      </div>
      </>
    );
  };