import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const UiUx = {
    title: "UI/UX",
    url: genrateURL("UI/UX"),
    description: "Intuitive and engaging interfaces that are easy to use and enhance user experience to drive visitors coming back repeatedly.    ",
    subServiceIcon: Icon.UIUX,
    metadescription: "This is Meta Descriptions for UI/Ux",
    metakeyword: "This is Meta Descriptions for UI/Ux",
    image: "UIUX.png",
    imgTag: 'UI/UX designing agency in grugaon',
    Maincontent: `<h2>Lorem ipsum dolor sit.</h2> <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit consectetur dolorum culpa alias tenetur amet saepe nam eius deleniti? Minima.</p> <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos officiis vitae libero, eligendi unde vero, voluptatibus nemo enim obcaecati est nostrum quia temporibus odio molestiae maiores. Ea sapiente dignissimos nam.</p> <p><b>Lorem ipsum dolor sit, amet consectetur adipisicing elit:</b></p> <ul> <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, officia.</li> <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime sunt ipsam eaque laborum quas tempore dolorem esse eligendi consectetur magnam.</li> <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, officia.</li> <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime sunt ipsam eaque laborum quas tempore dolorem esse eligendi consectetur magnam.</li> <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime sunt ipsam eaque laborum quas tempore dolorem esse eligendi consectetur magnam.</li> <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates, officia.</li> </ul>`,
    SecondSec:`<h3>Lorem ipsum dolor sit.</h3><p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit consectetur dolorum culpa alias tenetur amet saepe nam eius deleniti? Minima.</p> <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos officiis vitae libero, eligendi unde vero, voluptatibus nemo enim obcaecati est nostrum quia temporibus odio molestiae maiores. Ea sapiente dignissimos nam.</p><p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Reprehenderit consectetur dolorum culpa alias tenetur amet saepe nam eius deleniti? Minima.</p>`,

    ThirdSec:`<h4>Ajay ipsum dolor sit.</h4><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos officiis vitae libero, eligendi unde vero, voluptatibus nemo enim obcaecati est nostrum quia temporibus odio molestiae maiores. Ea sapiente dignissimos nam.</p>`
}

