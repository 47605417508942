import React , {useRef,useEffect,useState} from 'react';
import Marquee from "react-fast-marquee";
import { Icon } from '../Components/Assets';
import { Link } from 'react-router-dom';
import { GetToKnowUs } from '../Components/GetToKnowUs';
import { TeamSection } from '../Components/TeamSection';
import { PortfolioPop } from '../Components/PortfolioPop';
import CountUp from 'react-countup';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { WebSiteData } from "../Data/WebSiteData";
import { useMaster } from '../Components/MasterPage';
import { Helmet } from 'react-helmet';


export const Home = () => {

    const {state,dispatch,model,setModel,showPop,mobileview} = useMaster();
    const whyChooseUsRef = useRef(null);
    const slideRefs = useRef([]);
    const [rewamp,setRewamp] = useState(33);
    const serviceRef = useRef(null);

    useEffect(() => {
        if (WebSiteData?.homeBanner?.length > 0) {
          const intervalId = setInterval(() => {
            dispatch({ type: 'update', payload: { banner: (state.banner + 1) % WebSiteData.homeBanner.length } });
          }, 5000);
          return () => clearInterval(intervalId);
        }
      }, [dispatch, state.banner]);      

      useEffect(() => {
        slideRefs.current.forEach((content, index) => {
                if (content) {
                    content.style.height = index === state.serve ? `${content.scrollHeight}px` : '0';
                }
            });
        }, [state.serve]);

        const toggleFaq = (index) => {
            dispatch({ type: 'update', payload: { serve: index}})
        };
    

      useEffect(() => {
        const observer = new IntersectionObserver(([entry]) =>
            dispatch({ type: 'update', payload: { counter : entry.isIntersecting} })
        );
        const currentRef = whyChooseUsRef.current;
        if (currentRef) {
            observer.observe(currentRef);
        }
        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [dispatch, whyChooseUsRef]);

    return (
        <>
        <Helmet>
            <title>#1 Website Designing Company in Gurgaon | Coding Nectar</title>
            <meta name="title" content="#1 Website Designing Company in Gurgaon | Coding Nectar" />
            <meta name="description" content="Top-rated website designing company in Gurgaon. Coding Nectar offers professional web development, design, and SEO services to boost your online presence." />
            <meta name="keywords" content="Website Designing Company" />

            <meta property="og:site_name" content="Coding Nectar" />
            <meta property="og:url" content="https://www.codingnectar.com/" />
            <meta property="og:title" content="#1 Website Designing Company in Gurgaon | Coding Nectar" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Top-rated website designing company in Gurgaon. Coding Nectar offers professional web development, design, and SEO services to boost your online presence." />
            <meta property="og:image" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
            <meta property="og:image:secure_url" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta name="twitter:site" content="@codingnectar" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="#1 Website Designing Company in Gurgaon | Coding Nectar" />
            <meta name="twitter:description" content="Top-rated website designing company in Gurgaon. Coding Nectar offers professional web development, design, and SEO services to boost your online presence." />
            <link rel="canonical" href="https://codingnectar.com/" />
        </Helmet>
        <div className="home-banner">
            <div className="container">
                <div className="banner-wrapper">
                    <div className="content-wrap">
                        {WebSiteData?.homeBanner?.map((data, index) => {
                            const { title, description } = data;
                            const isActive = index === state.banner;
                            return (
                                <div className={`content ${isActive ? "active" : ''}`} key={index}>
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                    <button type="button" className='btn' onClick={() => showPop('enquire-pop')}>Discuss Project</button>
                                </div>
                            );
                        })}
                    </div>
                    <div className="banner-img">
                        {WebSiteData?.homeBanner?.map((data, index) => {
                            const { img,imgTag } = data;
                            const isActive = index === state.banner;
                            return (
                                <figure className={`img-banner ${isActive ? "active" : ''}`} key={index}>
                                    <img src={require(`../assets/images/home/banner/${img}`)} alt={imgTag} />
                                </figure>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
        <section>
            <div className="home-secA">
                <div className="container">
                    <Marquee autoFill={true}>
                        <figure><img src={require('../assets/images/home/logo/1.png')} alt="Website development and Digital marketing service" /></figure>
                        <figure><img src={require('../assets/images/home/logo/2.png')} alt="Website development and Digital marketing service" /></figure>
                        <figure><img src={require('../assets/images/home/logo/3.png')} alt="Website development and Digital marketing service" /></figure>
                        <figure><img src={require('../assets/images/home/logo/4.png')} alt="Website development and Digital marketing service" /></figure>
                        <figure><img src={require('../assets/images/home/logo/5.png')} alt="Website development and Digital marketing service" /></figure>
                    </Marquee>
                    <h1 className="secAtxt text-center">Web Development and Digital Marketing Services</h1>
                </div>
            </div>
        </section>
        <section>
            <div className="homesecB">
                <div className="container">
                    <div className="flex">
                        <div className="colA">
                            <div className="sub-heading">
                                <h6>{Icon.arrow} WE ARE DIGITAL EXPERTS</h6>
                            </div>
                            <div className="heading">
                                <h3>About Us</h3>
                                <p>We are a team of web developers, designers, and digital marketing experts, with the synergy of creativity, strategy, and technology to achieve digital success for brands. Positioned as the best website design and digital marketing company, we craft websites, advanced in functionality and optimized with current marketing standards. If you are not happy with your current website or brand positioning or want more lead conversions, then utilize our expertise and get a brand transformation. </p>
                            </div>
                            <ul className="col-Ms">
                                <li>100% Customizable and Flexible </li>
                                <li>Partner-centric Approach</li>
                                <li>Value for Money Services</li>
                            </ul>
                            <Link to="/about" className="btn">Discover More</Link>
                        </div>
                        <div className="colB">
                            <div className="card">
                                {WebSiteData?.aboutCompany?.map((data, index) => {
                                    const { title,content,img,imgTag } = data;
                                    return (
                                    <div className="col-abt-comp" key={index}>
                                        <div className="ico"><img src={img} alt={imgTag} /></div>
                                        <h5>{title}</h5>
                                        <p>{content}</p>
                                    </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="counter-sec" ref={whyChooseUsRef}>
                <div className="container">
                    <div className="sub-heading text-center">
                        <h6>{Icon.arrow} Website Design Development Partner</h6>
                    </div>
                    <div className="heading text-center">
                        <h3>Perfect Blend of Design, Technology, and Marketing Solutions</h3>
                        <p>Choosing Coding Nectar for Website Development and Digital Marketing services, you work with a team of versatile and visionary minds. Each of us are confident in our ability and dedicatedly work to craft a website that you’ll love. We take care of visuals, loading speed, user experience, search ranking performance and after launch website maintenance and support.</p>
                    </div>
                    <div className="grid">
                        {WebSiteData?.whyChooseUs?.map((data, index) => {
                            const { value,unit,description } = data;
                            return (
                                <div className="col" key={index} >
                                    <div className="ico">
                                        {Icon.icoCount}
                                        <div className="count">
                                        <h4>
                                            {state.counter ? (
                                                <CountUp
                                                    start={0}
                                                    end={value}
                                                    duration={2.75}
                                                    separator=" "
                                                    suffix={unit}
                                                />
                                            ) : (
                                                <>{value}</>
                                            )}
                                        </h4>
                                        </div>
                                    </div>
                                    <p>{description}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="home-secC">
                <div className="container">
                    <div className="sub-heading">
                        <h6>{Icon.arrow}Get Service</h6>
                    </div>
                    <div className="heading">
                        <h3>Projects We Deal In</h3>
                    </div>
                    {mobileview && mobileview ? 
                        <Swiper ref={serviceRef}
                            spaceBetween={16}
                            loop={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1.3,
                                    centeredSlides: false
                                },
                                675: {
                                    slidesPerView: 1,
                                },
                                767: {
                                    slidesPerView: 2,
                                }
                            }}
                            >
                            {WebSiteData?.homeweserve?.map((data, index) => {
                                const { name,icon,shortDescription,url } = data;
                                return (
                                    <SwiperSlide key={`service-${index}`} className='h-auto'>
                                        <div className="col h-100" key={index}>
                                            <div className="ico">{icon}</div>
                                            <figcaption>
                                                <h5>{name}</h5>
                                                <p>{shortDescription}</p>
                                                <Link className="read-more" to={`/${url}`} onClick={() => window.scrollTo({ top: 0 })}>Read More {Icon.arrow}</Link>
                                            </figcaption>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                            <div className="custom-btn-arrow">
                                <button className="team-prev invert-translate" onClick={() => serviceRef.current.swiper.slidePrev()}>
                                    {Icon.next}
                                </button>
                                <button className="team-next" onClick={() => serviceRef.current.swiper.slideNext()}>
                                    {Icon.next}
                                </button>
                            </div>
                        </Swiper>
                        :
                        <div className="grid">
                        {WebSiteData?.homeweserve?.map((data, index) => {
                            const { name,icon,shortDescription,url } = data;
                            return (
                                <div className="col" key={index}>
                                    <div className="ico">{icon}</div>
                                    <figcaption>
                                        <h5>{name}</h5>
                                        <p>{shortDescription}</p>
                                        <Link className="read-more" to={`/${url}`} onClick={() => window.scrollTo({ top: 0 })}>Read More {Icon.arrow}</Link>
                                    </figcaption>
                                </div>
                            );
                        })}
                    </div>
                    }
                </div>
            </div>
        </section>
        <section>
            <div className="faqSec we-serve-sec">
                <div className="container">
                    <div className="sub-heading text-center">
                        <h6>{Icon.arrow}How We Work?</h6>
                    </div>
                    <div className="heading text-center">
                        <h3>Making Brands Speak Volumes</h3>
                        <p>In the first one-o-one discussion with our clients, we are asked, what special do we do? We have a simple yet validating answer. As a Custom Web Development and SEO Marketing Agency, we use the same high-end custom designs with performing digital marketing methods that help you find Coding Nectar. </p>
                        <p>We begin with understanding the unique vision, goals, and audience. Next, we craft a user-friendly, visually appealing website tailored to provide an exceptional user experience. With our after-launch website support services and optimization, your brand achieves maximum online visibility, more organic traffic, and boost conversions. </p>
                    </div>
                    <div className="flex">
                        <div className="colA">
                            <div className="faq-sec">
                                {WebSiteData?.weServe?.slice(0,3).map((data, index) => {
                                    const { question, answer } = data;
                                    const isActive = index === state.serve;
                                    return (
                                        <div className={`col ${isActive ? 'active' : ''}`} key={index} onClick={() => {toggleFaq(index)}}>
                                            <h6>{question} 
                                                <div className="ico">
                                                    {Icon.next}
                                                </div>
                                            </h6>
                                            <div className="content" ref={el => slideRefs.current[index] = el}>
                                                <p>{answer}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="colB">
                            <div className="circle">
                                <div className="circle-wrap">
                                    {WebSiteData?.weServe?.slice(0, 3).map((data, index) => {
                                        const { icons } = data;
                                        const isActive = index === state.serve;
                                        return (
                                        <ul className={`circle-container ${isActive ? 'active' : ''}`} key={index}>
                                            {icons?.map((icon, iconIndex) => (
                                            <li key={iconIndex}>
                                                <div className="ico" data-title={icon.name}><img src={icon.img} alt={icon.imgTag} /></div>
                                            </li>
                                            ))}
                                        </ul>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <GetToKnowUs data={WebSiteData?.GetToKnowUs.filter(item => item.home)} />
        </section>
        <section>
            <div className="homeSecF">
                <div className="container">
                    <div className="sub-heading text-center">
                        <h6>{Icon.arrow} Projects</h6>
                    </div>
                    <div className="heading text-center">
                        <h3>Our Case Studies</h3>
                        <p>What is a development or revamp if it doesn’t make your brand smarter, more striking, and interactive to your audience? We have made it possible with each website development and digital marketing services delivered. Look at the clear picture of what we have achieved so far and added an ever-living brand experience to our portfolio.</p>
                    </div>
                    {mobileview && mobileview ? 
                        <Swiper
                            spaceBetween={16}
                            loop={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1.15,
                                    centeredSlides: false
                                },
                                675: {
                                    slidesPerView: 1,
                                },
                                767: {
                                    slidesPerView: 2,
                                }
                            }}
                            >
                            {(WebSiteData?.portfolio?.map((data, index) => {
                                    const {name,img,mobile,imgTag,imgWebsite,url} = data
                                    return (
                                        <SwiperSlide key={`case-study-${index}`}>
                                            <div className="item item-md" key={index}>
                                                <figure>
                                                    <img src={require(`../assets/images/portfolio/${img}`)} alt={imgTag} />
                                                </figure>
                                                <figcaption>
                                                    <div className="col-md">
                                                        <h5>{name}</h5>
                                                        <button className="btn" type='button' onClick={() => {showPop('portfolio-pop');setModel(prevModel => ({ ...prevModel, img: imgWebsite,url:url, mobile: mobile }))}}>Website View</button>
                                                    </div>
                                                </figcaption>
                                            </div>
                                        </SwiperSlide>
                                    );
                                }))}
                        </Swiper>
                        :
                            <div className="flex">
                                {(WebSiteData?.portfolio?.slice(0,4).map((data, index) => {
                                    const {name,img,mobile,url,imgTag,imgWebsite,} = data
                                    const isActive = index === state.hover;
                                    return (
                                        <div className={`item item-md ${isActive ? 'active' : ''}`} key={index}
                                            onMouseEnter={() => dispatch({ type: 'update', payload: {hover: index}})}
                                            onMouseLeave={() => dispatch({ type: 'update', payload: {hover: 1}})}
                                        >
                                            <figure>
                                                <img src={require(`../assets/images/portfolio/${img}`)} alt={imgTag} />
                                            </figure>
                                            <figcaption>
                                                <div className="col-md">
                                                    <h5>{name}</h5>
                                                    <button className="btn" type='button' onClick={() => {showPop('portfolio-pop');setModel(prevModel => ({ ...prevModel, img: imgWebsite,url:url, mobile: mobile }))}}>Website View</button>
                                                </div>
                                            </figcaption>
                                        </div>
                                    );
                                })) || <div className='no-data'>No data found</div>}
                            </div>
                    }
                </div>
            </div>
        </section>
        <section>
            <div className="rewamp-sec">
                <div className="container">
                    <div className="card">
                        <div className="heading text-center">
                            <h3>Transform Your Website & Digital Identity</h3>
                        </div>
                        <div className={`rewamp-wrap ${rewamp >= 70 ? 'active' : ''}`} style={{ '--visible': rewamp }}>
                            <div className="title" data-title="Revamp">Revamp</div>
                            <div className="comparison-result">
                                <figure>
                                    <div className="divisor"></div>
                                    <img src={require('../assets/images/home/car-before.png')} alt="Website development company" />
                                </figure>
                                <button type="button" className={`btn ${rewamp >= 70 ? 'active' : ''}`} onClick={() => showPop('enquire-pop')}>Get a Ride</button>
                            </div>
                            <input 
                                type="range" 
                                className="slider" 
                                min={1} 
                                max={100} 
                                value={rewamp} 
                                onChange={(e) => setRewamp(parseInt(e.target.value)) }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <TeamSection />
        </section>
        {/* <section>
            <Faq faqData={WebSiteData?.faq}/>
        </section> */}
        <section>
            <div className="blog-sec">
                <div className="container">
                    <div className="sub-heading text-center">
                        <h6>{Icon.arrow} Our Blog</h6>
                    </div>
                    <div className="heading text-center">
                        <h3>Recently On Our Blog</h3>
                    </div>
                    <div className="blog-slider">
                        <Swiper
                            spaceBetween={16}
                            loop={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    centeredSlides: false
                                },
                                675: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 3,
                                    centeredSlides: true
                                }
                            }}
                            >
                                {(WebSiteData?.blogs?.map((data, index) => {
                                    const { title,img,imgTag,description } = data;
                                    return (
                                        <SwiperSlide key={`blog-${index}`}>
                                            <div className="item">
                                                <figure><img src={require(`../assets/images/blog/${img}`)} alt={imgTag} /></figure>
                                                <figcaption>
                                                    <h6>{title}</h6>
                                                    <p>{description}</p>
                                                </figcaption>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })) || <div className='no-data'>No data found</div>}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className="testimonial-sec">
                <div className="container">
                    <div className="flex">
                        <div className="colA">
                            <div className="sub-heading">
                                <h6>{Icon.arrow}Testimonials</h6>
                            </div>
                            <div className="heading">
                                <h3>Reviews that speak volumes</h3>
                                <p>Don't just take are word for it, hear what people have to say about us.</p>
                            </div>
                        </div>
                        <div className="colB">
                            <div className="col">
                                <div className="ico">{Icon.testimonial}</div>
                                {(WebSiteData?.testimonial?.map((data, index) => {
                                        const { title,name,description,designation } = data;
                                        const isActive = index === state.testimonial;
                                        return (
                                            <div className={`card ${isActive ? 'active' : ''}`} key={index}>
                                                <div className="upper-sec">
                                                    <h6>{title}</h6>
                                                    <div className="content">
                                                        <p>"{description}"</p>
                                                    </div>
                                                </div>
                                                <div className="name-inf">
                                                    <h4>{name}</h4>
                                                    <p>{designation}</p>
                                                </div>
                                            </div>
                                        );
                                    })) || <div className='no-data'>No data found</div>}
                            </div>
                            <div className="testimonial-slider">
                                <Swiper
                                    spaceBetween={16}
                                    slidesPerView={3}
                                    centeredSlides={true}
                                    loop={true}
                                    autoplay={false}
                                    // autoplay={{
                                    //     delay: 3000,
                                    //     disableOnInteraction: false,
                                    // }}
                                    modules={[Autoplay]}
                                    onSlideChange={(swiper) => dispatch({ type: 'update', payload: {testimonial: swiper.realIndex}})}
                                    >
                                        {(WebSiteData?.testimonial?.map((data, index) => {
                                                const { img,imgTag } = data;
                                            return (
                                                <SwiperSlide key={`testimonial-${index}`}>
                                                    <figure><img src={require(`../assets/images/testimonial/${img}`)} alt={imgTag} /></figure>
                                                </SwiperSlide>
                                            );
                                        }))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <PortfolioPop model={model} setModel={setModel} />
        </>
    );
};
