import React from 'react';
import { Link,NavLink } from 'react-router-dom';
import { Home } from '../Pages/Home';
import { About } from '../Pages/About';
import { Services } from '../Pages/Services';
import { Portfolio } from '../Pages/Portfolio';
import { FunGallery } from '../Pages/Fun Gallery';
import { Contact } from '../Pages/Contact';
import { PrivacyPolicy } from '../Pages/PrivacyPolicy';
import { TermsCondition } from '../Pages/TermsCondition';
import { useMaster } from './MasterPage';
import { ServiceData } from '../Data/ServiceData';

export const NavData = [
  {
    title: "Home",
    url: "/",
    PageName: Home,
  },
  {
    title: "About",
    url: "/about",
    PageName: About,
  },
  {
    title: "Services",
    url: "/services",
    PageName: Services,
  },
  {
    title: "Portfolio",
    url: "/portfolio",
    PageName: Portfolio,
  },
  {
    title: "Fun Gallery",
    url: "/fun-gallery",
    PageName: FunGallery,
  },
  {
    title: "Contact Us",
    url: "/contact",
    PageName: Contact,
  },
  // {
  //   title: "Privacy Policy",
  //   url: "/privacy-policy",
  //   PageName: PrivacyPolicy,
  // },
  {
    title: "Terms & Conditions",
    url: "/terms-conditions",
    PageName: TermsCondition,
  }
];

export const Nav = ({ className,Dropdown}) => {
  const {state,dispatch} = useMaster();
  return (
    <>
      {NavData.slice(0, 6).map((navdata,index) => {
        const { title, url } = navdata;
        if (index === 2 && Dropdown) {
          return (
            <li key={index} className={`${className} hasDropdown`}>
              <NavLink to={url} onClick={() => window.scrollTo({top: 0})}>{title}</NavLink>
              <div className="service-dropdown">
                  <div className="dropdown-menu-wrap">
                      <div className="colA-drp">
                          <ul>
                            {ServiceData?.map((service, servicetabindex) => {
                                const { name,url,image,subServices } = service;
                                const IsserviceTab = servicetabindex === state.dropdownTab;
                                return (
                                    <li key={servicetabindex} className={`${IsserviceTab ? 'active' : ''}`}>
                                        <Link to={url} onClick={() => window.scrollTo({top: 0})}>{name}</Link>
                                        <div className="tabs">
                                          <div className="col-md-1">
                                              <ul>
                                                  {subServices?.map((subService, subserviceindex) => (
                                                      <li key={`sub-service-${servicetabindex}-${subserviceindex}`}
                                                          onMouseEnter={() => dispatch({ type: 'update', payload: { dropdownImg: subService?.subServiceIcon } })}
                                                          onMouseLeave={() => dispatch({ type: 'update', payload: { dropdownImg: null } })}
                                                      >
                                                          {/* <Link to={`${url}/${subService?.url}`}>{subService?.title}</Link> */}
                                                          <Link to={`${url}`}>{subService?.title}</Link>
                                                      </li>
                                                  ))}
                                                  {(!subServices || subServices.length === 0) && <li><Link to={url}>{name}</Link></li>}
                                              </ul>
                                          </div>
                                          <div className="col-md-2">
                                              <figure>
                                                  <img src={state.dropdownImg || (subServices && subServices.length > 0 && require(`../assets/images/banner/${image}`)) || ''} alt=""/>
                                              </figure>
                                          </div>
                                      </div>
                                    </li>
                                );
                            })}
                          </ul>
                      </div>
                  </div>
              </div>
            </li>
          );
        } else {
          return (
            <li key={index} className={className}>
              <NavLink to={url} onClick={() => window.scrollTo({top: 0})}>{title}</NavLink>
            </li>
          );
        }
      })}
    </>
  );
};
