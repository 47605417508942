import React from 'react';
import { Helmet } from "react-helmet";
import { useMaster } from '../Components/MasterPage';
import { useParams,Link, NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { Icon } from '../Components/Assets';
import { Banner } from '../Components/Banner';
import { ServiceData } from '../Data/ServiceData';
import { Testimonial } from '../Components/Testimonial';
import { Faq } from '../Components/Faq';

export const ServiceDetail = () => {
    const {mobileview} = useMaster();
    const { servicedetail } = useParams();
    const services = ServiceData.find(el => el.url === servicedetail);

    return (
      <>
        <Helmet>
          <title>{services ? services.pageTitle: 'Service Not Found'}</title>
          <meta name="title" content={services.pageTitle}></meta>
          <meta name='description' content={`${services?.metadescription}`} />
          <meta name='keywords' content={`${services?.metakeyword}`} />

          <meta property="og:site_name" content="Coding Nectar" />
          <meta property="og:url" content={services.metaUrl} />
          <meta property="og:title" content={services.pageTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={`${services?.metadescription}`} />
          <meta property="og:image" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
          <meta property="og:image:secure_url" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
          <meta name="twitter:site" content="@codingnectar" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={services.pageTitle} />
          <meta name="twitter:description" content={`${services?.metadescription}`} />
          <link rel="canonical" href={services.metaUrl} />
        </Helmet>
        <Banner className={'service-detail-banner'} heading={services?.name} subHeading={services ? services.name : 'Page Not Found'} img={services ? services?.image : 'contact-banner.png'} imgTag={services.imgTag}/>
        <section>
          <div className="service-dtl">
            <div className="container">
              <div className="content">
                {services && services.description && <h5 className='consecA'>{services.description}</h5>}
                <div className="col-md">
                  {services?.subServices &&
                      <div className="colA">
                        <div className="title">
                          <h4>Services</h4><span></span>
                        </div>
                        {/* <ul>
                          {services?.subServices?.map((subService, subServiceIndex) => (
                            // <li key={subServiceIndex}><NavLink to={`/${servicedetail}/${subService.url}`} onClick={() => window.scrollTo({ top: 0 })}>{subService.title}</NavLink></li>
                            <li key={subServiceIndex}><Link to={`/${servicedetail}`} onClick={() => window.scrollTo({ top: 0 })}>{subService.title}</Link></li>
                          ))}
                        </ul> */}
                        <ul>
                          {ServiceData?.map((data, index) => {
                              const { name,url} = data;
                              return (
                                <li><NavLink onClick={() => window.scrollTo({top: 0})} className="col" to={`/${url}`} key={index} >{name}</NavLink></li>
                              );
                          })}
                        </ul>
                      </div>
                    }
                  <div className="colB">
                    {services?.content1 && <div className="content" dangerouslySetInnerHTML={{ __html: services.content1 }}></div>}
                    {services?.content2 && <div className="content-sec2" dangerouslySetInnerHTML={{ __html: services.content2 }}></div>}
                    {services?.subServiceheding && <div className="sub-service-heading" dangerouslySetInnerHTML={{ __html: services?.subServiceheding }}></div>}
                    {services?.types && 
                      <div className="sub-service-sec">
                          <div className="grid">
                          {services?.types.map((data, index) => {
                            const { title, description, Icon, imgTag } = data;
                            return (
                              <div className="col" key={index}>
                                <figure>
                                  <img src={Icon} alt={imgTag} />
                                </figure>
                                <figcaption>
                                  <h6>{title}</h6>
                                  <p>{description}</p>
                                </figcaption>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      }
                    {services?.p1 && <div className='content-sec2' dangerouslySetInnerHTML={{ __html: services.p1 }}></div>}
                    {mobileview && mobileview ? 
                      <div className="sub-service-sec sub-service-sec2">
                          <Swiper
                              spaceBetween={16}
                              loop={true}
                              autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                              }}
                              modules={[Autoplay]}
                              breakpoints={{
                                  0: {
                                      slidesPerView: 1.2,
                                      centeredSlides: false,
                                      spaceBetween: 0
                                  },
                                  675: {
                                      slidesPerView: 2,
                                  }
                              }}
                              >
                              {services?.subServices?.map((data, index) => {
                                  const { title, description, url,subServiceIcon, imgTag } = data;
                                  return (
                                      <SwiperSlide key={`service-${index}`} className='p-8'>
                                          <div className="col" key={index}>
                                              <figure>
                                                <img src={subServiceIcon} alt={imgTag} />
                                              </figure>
                                              <figcaption>
                                                <h6>{title}</h6>
                                                <p>{description}</p>
                                                {/* <Link className='read-more' to={`/${servicedetail}`} onClick={() => window.scrollTo({ top: 0 })}>Read More {Icon.arrow}</Link> */}
                                                {/* for sub-servce url activation
                                                <Link className='read-more' to={`/${servicedetail}/${url}`}>Read More {Icon.arrow}</Link> */}
                                              </figcaption>
                                            </div>
                                      </SwiperSlide>
                                  );
                              })}
                          </Swiper>
                        </div>
                          :
                          <div className="sub-service-sec sub-service-sec2">
                            <div className="grid grid-two">
                            {services?.subServices?.map((data, index) => {
                                  const { title, description, url,subServiceIcon, imgTag } = data;
                                  return (
                                    <div className="col p-standard" key={index}>
                                      <figure>
                                        <img src={subServiceIcon} className='w-200' alt={imgTag} />
                                      </figure>
                                      <figcaption>
                                        <h6>{title}</h6>
                                        <p>{description}</p>
                                        {/* <Link className='read-more' to={`/${servicedetail}`}>Read More {Icon.arrow}</Link> */}
                                        {/* for subservice url activation  */}
                                        {/* <Link className='read-more' to={`/${servicedetail}/${url}`}>Read More {Icon.arrow}</Link> */}
                                      </figcaption>
                                    </div>
                                  )
                                })}
                            </div>
                        </div>
                    }
                    {services?.benifits && (
                        <div className="benifits-of-services">
                          <div className="title" dangerouslySetInnerHTML={{ __html: services?.benifits?.description }}></div>
                          <div className="col-md-benifit">
                            {mobileview ? (
                              <Swiper className='p-8'
                                spaceBetween={16}
                                loop={true}
                                breakpoints={{
                                  0: {
                                    slidesPerView: 1.2,
                                    centeredSlides: false
                                  },
                                  676: {
                                    slidesPerView: 2,
                                  }
                                }}
                                autoplay={{
                                  delay: 3000,
                                  disableOnInteraction: false,
                                }}
                                modules={[Autoplay]}
                              >
                                {services?.benifits?.content?.map((data, index) => {
                                  const { title, Icon, imgTag, summery } = data;
                                  return (
                                    <SwiperSlide key={`service-${index}`}>
                                      <div className="col" key={index}>
                                        <div className="colA">
                                          <img src={Icon} alt={imgTag} className="icon" />
                                          <h5>{title}</h5>
                                        </div>
                                        <div className="colB">
                                          {summery}
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            ) : (
                              services?.benifits?.content?.map((data, index) => {
                                const { title, Icon, imgTag, summery } = data;
                                return (
                                  <div className="col" key={index}>
                                    <div className="colA">
                                      <img src={Icon} alt={imgTag} className="icon" />
                                      <h5>{title}</h5>
                                    </div>
                                    <div className="colB">
                                      {summery}
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Testimonial/>
        </section>
        <section>
          <Faq additionalClass="faq-service" faqData={services ? services.faq : []}/>
        </section>
      </>
    );
};
