import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const YouTubeMarketing = {
    title: "YouTube Marketing",
    url: genrateURL("YouTube Marketing"),
    description: "Channel Management | Content Creation | Community Building | Monetization Strategies",
    subServiceIcon: Icon.YoutubeMarketing,
    image: "YoutubeMarketing.png",
    imgTag: 'social media marketing company'
}