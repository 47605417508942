import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const BrandBuilding =  {
    title: "Brand Building",
    url: genrateURL("Brand Building"),
    description: "Brand Guidelines | Website Design | Campaign Strategy | Engagement Material",
    subServiceIcon: Icon.BrandingBuilding,
    image: "BrandingBuilding.png",
    imgTag: 'Best Graphic design companies'
}