import React from 'react';
import {Helmet} from "react-helmet";
import { Banner } from '../Components/Banner';
import { Link } from 'react-router-dom';
import { ServiceData } from '../Data/ServiceData';
export const Services = () => {
    return (
      <>
      <Helmet>
          <title>Our Services | Website Design, SEO & More | Coding Nectar</title>
          <meta name="title" content="Our Services | Website Design, SEO & More | Coding Nectar" />
          <meta name="description" content="Explore Coding Nectar's range of services including website design, SEO, and digital marketing. Tailored solutions to enhance your online presence. Learn more now!" />
          <meta name="keywords" content="Top Website and Digital Marketing Company in Gurgaon" />

          <meta property="og:site_name" content="Coding Nectar" />
          <meta property="og:url" content="https://www.codingnectar.com/services" />
          <meta property="og:title" content="Our Services | Website Design, SEO & More | Coding Nectar" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Explore Coding Nectar's range of services including website design, SEO, and digital marketing. Tailored solutions to enhance your online presence. Learn more now!" />
          <meta property="og:image" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
          <meta property="og:image:secure_url" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="628" />
          <meta name="twitter:site" content="@codingnectar" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Our Services | Website Design, SEO & More | Coding Nectar" />
          <meta name="twitter:description" content="Explore Coding Nectar's range of services including website design, SEO, and digital marketing. Tailored solutions to enhance your online presence. Learn more now!" />
          <link rel="canonical" href="https://www.codingnectar.com/services" />
      </Helmet>
      <Banner className={'service-listing-banner'} heading={'Services'} subHeading={'Services'} img={'SERVICES.png'} imgTag={'Alt title here'}/>  
      <div className="serviceSecA">
        <div className="container">
          <div className="grid">
              {ServiceData?.map((data, index) => {
                  const { name,icon,shortDescription,url } = data;
                  return (
                    <Link className="col" to={`/${url}`} key={index} onClick={() => window.scrollTo({top: 0})}>
                         <div className="ico">{icon}</div>
                          <figcaption>
                              <h4>{name}</h4>
                              <p>{shortDescription}</p>
                          </figcaption>
                    </Link>
                  );
              })}
          </div>
        </div>
      </div>
      </>
    );
  };

  