import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const SocialMediaPost = {
    title: "Social Media Post",
    url: genrateURL("Social Media Post"),
    description: "Image Posts | Video Reels | Carousels | Stories | Infographics ",
    subServiceIcon: Icon.soialMediaPost,
    image: "soialMediaPost.png",
    imgTag: 'Hire best social media Graphic designer'
}