import React from 'react';
import { Icon } from '../Components/Assets';
import { Link } from 'react-router-dom';
import { useMaster } from '../Components/MasterPage';

export const GetToKnowUs = ({ additionClass, data }) => {
    const {showPop} = useMaster();
    return (
        <>
            {data && 
                data.map((content, index) => {
                    const { home, about } = content;
                    const { img, imgTag, title,subTitle, description, url, col, btnName } = about || home;
                    return (
                        <div className={`home-secD ${additionClass || ''}`} key={index}>
                            <div className="container">
                                <div className="flex">
                                    <div className="colA">
                                        <figure><img src={require(`../assets/images/home/${img}`)} alt={imgTag} /></figure>
                                    </div>
                                    <div className="colB">
                                        <div className="col-md">
                                            <div className="sub-heading">
                                                <h6>{Icon.arrow} {subTitle}</h6>
                                            </div>
                                            <div className="heading">
                                                <h3>{title}</h3>
                                                <div className='content-desc' dangerouslySetInnerHTML={{ __html: description }}></div>
                                            </div>
                                            <ul className="ms-col">
                                                {col?.map((item, index) => (
                                                    <li className="col" key={index}>
                                                        <div className="ico">
                                                            <figure><img src={item.img} alt={item.imgTag} /></figure>
                                                        </div>
                                                        <div className="cont">
                                                            <h5>{item.title}</h5>
                                                            
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            <Link className="btn" onClick={() => showPop('enquire-pop')} >{btnName}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </>
    );
};
