import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const WebsiteMaintenance = {
    title: "Website Maintenance",
    url: genrateURL("Website Maintenance"),
    description: "After launch, get ongoing support, maintenance, reporting, and updates to ensure your website runs smoothly and stays up-do-date.",
    subServiceIcon: Icon.websiteMaintenamce,
    image: "websiteMaintenamce.png",
    imgTag: 'website maintenance and Website Development agency in gurgaon'
}