import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const WebsiteRevamps = {
    title: "Website Revamps",
    url: genrateURL("Website Revamps"),
    description: "Transform outdated websites with advanced designs and functionalities to improve performance and customer interactions.",
    subServiceIcon: Icon.WebsiteRevamps,
    image: "WebsiteRevamps.png",
    imgTag: 'Website Revamp and designing agency in gurgaon'
}