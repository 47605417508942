import React from 'react';
import { Icon } from '../Components/Assets';
import { useMaster } from '../Components/MasterPage';
import { WebSiteData } from '../Data/WebSiteData';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const Testimonial = ({ additionalClass }) => {
    const {mobileview} = useMaster();
    return (
      <>
          <div className={`testimonial-service ${additionalClass || ''}`}>
              <div className="container">
                  <div className="sub-heading text-center">
                      <h6>{Icon.arrow} Testimonials</h6>
                  </div>
                  <div className="heading text-center">
                    <h3>Reviews that speak volumes</h3>
                    <p>Don't just take are word for it, hear what people have to say about us.</p>
                  </div>
                  {mobileview ? 
                      <Swiper
                          spaceBetween={16}
                          loop={true}
                          breakpoints={{
                              0: {
                                  slidesPerView: 1.3,
                                  centeredSlides: false
                              },
                              675: {
                                  slidesPerView: 2,
                              }
                          }}
                          >
                          {(WebSiteData?.testimonial?.map((data, index) => {
                              const { title,name,description,img,imgTag,designation } = data;
                              return (
                                <SwiperSlide>
                                  <div className="col-md" key={index}>
                                    <figure>
                                        <img src={require(`../assets/images/testimonial/${img}`)} alt={imgTag} />
                                    </figure>
                                    <figcaption>
                                        <div className="ico">{Icon.testimonial}</div>
                                        <h6>{title}</h6>
                                        <p>"{description}"</p>
                                        <div className="btm">
                                          <h5>{name}</h5>
                                          <p>{designation}</p>
                                        </div>
                                    </figcaption>
                                  </div>
                                </SwiperSlide>
                              );
                          }))}
                      </Swiper>
                      :
                      <div className="col">
                          {(WebSiteData?.testimonial?.slice(0,2).map((data, index) => {
                              const { title,name,description,img,imgTag,designation } = data;
                              return (
                                  <div className="col-md" key={index}>
                                    <figure>
                                        <img src={require(`../assets/images/testimonial/${img}`)} alt={imgTag} />
                                    </figure>
                                    <figcaption>
                                        <div className="ico">{Icon.testimonial}</div>
                                        <h6>{title}</h6>
                                        <p>"{description}"</p>
                                        <div className="btm">
                                          <h5>{name}</h5>
                                          <p>{designation}</p>
                                        </div>
                                    </figcaption>
                                  </div>
                              );
                          }))}
                    </div>
                  }
          </div>
      </div>
      </>
    );
  };