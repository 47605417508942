import React from 'react';
import { Helmet } from "react-helmet";
import { useParams, NavLink } from 'react-router-dom';
import { Banner } from '../Components/Banner';
import { ServiceData } from '../Data/ServiceData';
import { Testimonial } from '../Components/Testimonial';

export const SubServiceDetail = () => {
  const { servicedetail, subservice } = useParams();
  const detail = ServiceData.find(el => el.url === servicedetail);
  const subservicePage = detail ? detail.subServices.find(pr => pr.url === subservice) : null;
  return (
    <>
      <Helmet>
        <title>{subservicePage ? subservicePage.title : 'Service Not Found'} | {detail?.name }</title>
        <meta name='description' content={`${subservicePage?.metadescription}`} />
        <meta name='keywords' content={`${subservicePage?.metakeyword}`} />
      </Helmet>
      <Banner className={'service-detail-banner'} heading={subservicePage ? subservicePage.title : ''} subHeading={subservicePage ? subservicePage.title : 'Service Not Found'} img={subservicePage ? subservicePage?.image : 'contact-banner.png'} imgTag={'Alt title here'}/>
      <div className="sub-servdtlsecA">
        <div className="container">
            {detail?.name && 
                <div className="colA">
                <div className="title">
                  <h4>Services</h4><span></span>
                </div>
                <ul>
                  {detail?.subServices?.map((subService, subServiceIndex) => (
                    <li key={subServiceIndex}><NavLink to={`/${servicedetail}/${subService.url}`} onClick={() => window.scrollTo({ top: 0 })}>{subService.title}</NavLink></li>
                  ))}
                </ul>
              </div>
            }
            {subservicePage?.Maincontent && (
                <div className="colB">
                    <div className="content FirstSec" dangerouslySetInnerHTML={{ __html: subservicePage.Maincontent }}></div>
                    <div className="content SecondSec" dangerouslySetInnerHTML={{ __html: subservicePage.SecondSec }}></div>
                    <div className="content ThirdSec" dangerouslySetInnerHTML={{ __html: subservicePage.SecondSec }}></div>
                </div>
            )}
        </div>
      </div>
      <section>
        <Testimonial/>
      </section>
    </>
  );
};
