import React, { useState,useEffect } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ServiceData } from '../Data/ServiceData';
import { useNavigate } from 'react-router-dom';
import { useMaster } from '../Components/MasterPage';

const validateName = {
    required: 'Please Enter Name*',
    minLength: {
        value: 3,
        message: 'Minimum 3 characters',
    },
    maxLength: {
        value: 50,
        message: 'Maximum 20 characters',
    },
};

const validateContact = {
    pattern: {
        value: /^[0-9]{10}$/,
        message: 'Invalid Number',
    },
    required: 'Please Enter Contact*',
};

const validateEmail = {
    required: 'Please Enter Email*',
    pattern: {
        value: /\S+@\S+\.\S+/,
        message: 'Invalid email address',
    },
};

const validateMessage = {
    required: 'Enter Message',
    minLength: {
        value: 0,
        message: 'Minimum 0 characters',
    },
    maxLength: {
        value: 200,
        message: 'Maximum 200 characters',
    },
};

export const validateSelectboxservice = {
    required: 'Please select a service*',
};

const Input = ({ type, name, value, error, label, onChange }) => {
    return (
        <div className="form-group">
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
            />
            <label htmlFor={name} className={`${value !== '' ? 'valid' : ''} ${error ? 'error' : ''}`}>
                {error ? error : label}
            </label>
        </div>
    );
};

const TextArea = ({ name, value, error, label, onChange }) => {
    return (
        <div className="form-group message-box">
            <textarea 
                name={name}
                value={value}
                onChange={onChange}
            >
            </textarea>
            <label htmlFor={name} className={`${value !== '' ? 'valid' : ''} ${error ? 'error' : ''}`}>
                {error ? error : label}
            </label>
        </div>
    );
};

const SelectBox = ({ name, label, options, value, onChange, error }) => {
    const [selectedValue, setSelectedValue] = useState(value || '');
    const [openSelect, setOpenSelect] = useState(false);

    useEffect(() => {
        const handleBodyClick = (event) => {
            if (!event.target.closest('.custom-select')) {
                setOpenSelect(false);
            }
        };
        document.addEventListener('click', handleBodyClick);
        return () => {
            document.removeEventListener('click', handleBodyClick);
        };
    }, []);

    const handleOptionClick = (value) => {
        setSelectedValue(value !== null ? value : '');
        onChange(value);
    };

    return (
        <div className="form-group">
            <div className="custom-select">
                <span className={`current ${selectedValue ? 'valid' : ''}${error ? ' error' : ''}`} onClick={() => setOpenSelect(!openSelect)}>
                    {selectedValue || (error ? error : label ? label : 'Select Value')}
                </span>
                <ul className={`list ${openSelect ? 'is-open' : '' }`}>
                    {label && <li key={0} onClick={() => {handleOptionClick(null);setOpenSelect(!openSelect)}}>{label}</li>}
                    {options.map((option, index) => (
                        <li key={label ? index + 1 : index} onClick={() => {handleOptionClick(option);setOpenSelect(!openSelect)}}>
                            {option}
                        </li>
                    ))}
                </ul>
                <input type="text" hidden name={name} value={selectedValue} onChange={onChange} />
            </div>
            {selectedValue && <label htmlFor={name} className='valid'>Service*</label>}
        </div>
    );
};



export const UserForm = () => {
    const methods = useForm();
    const navigate = useNavigate();
    const {dispatch} = useMaster();

    const onSubmit = async (data) => {
        dispatch({ type: 'update', payload: { loader: true } }); 

        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const date = `${day}/${month}/${year}`;
        const time = now.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
        
        const submissionData = {
            ...data,
            date: date,
            time: time
        };
    
        const scriptURL = 'https://script.google.com/macros/s/AKfycbzWZ5fSOBqMP16TzxGUcfJGgbNa-4-MEgIYp0AaDFPsRURAc-dzKw4qFRBw8c68lWQ3lA/exec';
    
        // https://script.google.com/macros/s/AKfycbzPPdu9KfhhVZqNeEr1oY57TNxZ8v0hgsyb8IlOxLZ32dZG-5ASwoUGHTdiIKQNWfFh/exec   for testing ajayadvology mail id


        try {
            const response = await fetch(scriptURL, {
                method: 'POST',
                body: new URLSearchParams(submissionData)
            });
            const result = await response.json();
            methods.reset();
            dispatch({ type: 'update', payload: { thank: true } })
        } catch (error) {
            alert('Something Went Wrong');
            console.error('Error!', error.message);
        } finally {
            dispatch({ type: 'update', payload: { loader: false } }); 
            console.log(submissionData);
        }
    };    

    return (
        <FormProvider {...methods}>
            <form id="myForm" onSubmit={methods.handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={methods.control}
                    defaultValue=""
                    rules={validateName}
                    render={({ field, fieldState }) => (
                        <Input
                            type="text"
                            name="name"
                            value={field.value}
                            onChange={field.onChange}
                            error={fieldState.error?.message}
                            label="Full Name"
                        />
                    )}
                />
                <Controller
                    name="contact"
                    control={methods.control}
                    defaultValue=""
                    rules={validateContact}
                    render={({ field, fieldState }) => (
                        <Input
                            type="text"
                            name="contact"
                            value={field.value}
                            onChange={field.onChange}
                            error={fieldState.error?.message}
                            label="Phone*"
                            pattern="[a-zA-Z0-9+]*"
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={methods.control}
                    defaultValue=""
                    rules={validateEmail}
                    render={({ field, fieldState }) => (
                        <Input
                            type="text"
                            name="email"
                            value={field.value}
                            onChange={field.onChange}
                            error={fieldState.error?.message}
                            label="Email*"
                        />
                    )}
                />
                <Controller
                    name="service"
                    control={methods.control}
                    defaultValue=""
                    rules={validateSelectboxservice}
                    render={({ field, fieldState }) => (
                        <SelectBox
                            name="service"
                            label="Select Service"
                            options={ServiceData?.map(service => service.name)}
                            value={field.value}
                            onChange={field.onChange}
                            error={fieldState.error?.message}
                        />
                    )}
                />
                <Controller
                    name="date"
                    control={methods.control}
                    defaultValue={new Date().toISOString().split('T')[0]}
                    render={({ field }) => (
                        <input type="hidden" {...field} />
                    )}
                />
                <Controller
                    name="time"
                    control={methods.control}
                    defaultValue={new Date().toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true
                    })}
                    render={({ field }) => (
                        <input type="hidden" {...field} />
                    )}
                />
                {/* <Controller
                    name="Message"
                    control={methods.control}
                    defaultValue=""
                    rules={validateMessage}
                    render={({ field, fieldState }) => (
                        <TextArea
                            name="Message"
                            value={field.value}
                            onChange={field.onChange}
                            error={fieldState.error?.message}
                            label="Message*"
                        />
                    )}
                /> */}
                <button type="submit" className='btn'>Ready to Connect</button>
            </form>
        </FormProvider>
    );
};
