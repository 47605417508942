import { Icon } from "../../Components/Assets";
import { genrateURL } from "../../Components/Assets";

export const ECommerce = {
    title: "E-commerce",
    url: genrateURL("E-commerce"),
    description: "Small or large-scale E-commerce platform totally secure and scalable to boost sales and provide a seamless shopping experience.",
    subServiceIcon: Icon.Ecommerce,
    image: "Ecommerce.png",
    imgTag: 'E-Commerce Website Development agency in gurgaon'
}