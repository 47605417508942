import React from 'react';
import { Banner } from '../Components/Banner';
import { WebSiteData } from '../Data/WebSiteData';
import { GetToKnowUs } from '../Components/GetToKnowUs';
import { TeamSection } from '../Components/TeamSection';
import {Helmet} from "react-helmet";
export const About = () => {
  return (
    <>
    <Helmet>
        <title>About Us - Coding Nectar</title>
        <meta name="title" content="About Us - Coding Nectar" />
        <meta name="description" content="Learn about Coding Nectar, the leading website designing company in Gurgaon. Discover our expert web development, design, and SEO services. Join us today!" />
        <meta name="keywords" content="Website Designing and Digital Marketing Agency" />
        
        <meta property="og:site_name" content="Coding Nectar" />
        <meta property="og:url" content="https://www.codingnectar.com/about" />
        <meta property="og:title" content="About Us - Coding Nectar" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Learn about Coding Nectar, the leading website designing company in Gurgaon. Discover our expert web development, design, and SEO services. Join us today!" />
        <meta property="og:image" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
        <meta property="og:image:secure_url" content="https://www.codingnectar.com/static/media/logo.2171f6c1ec8fcf589149.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <meta name="twitter:site" content="@codingnectar" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Coding Nectar" />
        <meta name="twitter:description" content="Learn about Coding Nectar, the leading website designing company in Gurgaon. Discover our expert web development, design, and SEO services. Join us today!" />
        <link rel="canonical" href="https://www.codingnectar.com/about" />
    </Helmet>
    <Banner className={'about-banner'} heading={'About Us'} subHeading={'About Us'} img={'BanAboutNew.png'} imgTag={'Alt title here'}/>
      <section>
        <div className="about-secA">
            <div className="container">
                <p>Being visible is not enough for digital success, but being vocal is what defines your brand.
Be it a start-up or an industry icon, we make it happen, to transform ideas into digital
masterpieces! From the first pixel to the final code, and a ‘Tadka’ of marketing strategies, we
are your partners in crafting stunning websites, enticing graphics, and powerful marketing
campaigns.</p>
            </div>
        </div>
      </section>
      <section>
        <GetToKnowUs data={WebSiteData?.GetToKnowUs.filter(item => item.about)} additionClass={"aboutpg"} />
      </section>
      <section>
          <div className="mission-vission">
            <div className="container">
                <div className="col">
                    {(WebSiteData?.aboutVisionMission?.map((data, index) => {
                          const { title,description,img,imgTag } = data;
                          return (
                            <div className="col-md" key={index}>
                              <figure>
                                  <img src={require(`../assets/images/about/${img}`)} alt={imgTag} />
                              </figure>
                              <figcaption>
                                  <div className="heading">
                                      <h3>{title}</h3>
                                  </div>
                                  <div className="content" dangerouslySetInnerHTML={{ __html: description }}>
                                      {/* <p>{description}</p> */}
                                  </div>
                              </figcaption>
                            </div>
                          );
                      })) || <div className='no-data'>No data found</div>}
                </div>
                <p>Our secret sauce? We believe <span> "Jo darr gaya, samjho marr gaya"</span>. We aren't afraid to push
the boundaries and think outside the box to deliver exceptional results. No matter, what your
industry, our team’s dedication, and innovative thinking ensure that your brand stands out in
the digital landscape.</p>
            </div>
          </div>
      </section>
      <section>
            <div className="about-secC">
                <div className="container">
                    <div className="grid">
                      {WebSiteData?.aboutsection4?.map((data, index) => {
                            const { title,content,img,imgTag } = data;
                            return (
                            <div className="col-abt-comp fz18" key={index}>
                                <div className="ico"><img src={img} alt={imgTag} /></div>
                                <h5>{title}</h5>
                                <p>{content}</p>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </div>
      </section>
      <section>
            <TeamSection additionalClass={"about-SecD"} />
      </section>
    </>
  );
};

