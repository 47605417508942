export const Banner = ({ className,heading,subHeading,img,imgTag }) => {
    return (
      <div className={`banner ${className || ''}`}>
          <div className="container">
              <div className="content">
                  <h1>{heading}</h1>
                  <div className="bottom-sec">
                      <h2>{subHeading}</h2>
                      <img className="banner-img" src={require(`../assets/images/banner/${img}`)} alt={imgTag} />
                  </div>
              </div>
          </div>
      </div>
    );
  };